// Styles
// Import Flag Icons Set
// @import '~flag-icon-css/css/flag-icon.min.css';
// Import Simple Line Icons Set
// @import '~simple-line-icons/css/simple-line-icons.css';
// Import Font Awesome Icons Set
@import "~font-awesome/css/font-awesome.min.css";
// If you want to override variables do it here
@import "variables";
// Import styles
@import "./coreui/coreui.scss";
// Temp fix for reactstrap
@import "./coreui/_dropdown-menu-right.scss";
// If you want to add something do it here
@import "custom";
// ie fixes
@import "ie-fix";
