.Modal-show-loading-data-modal {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.06);
  opacity: 0;
  visibility: hidden;
  transform: scaleX(1.1) scaleY(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  font-family: sans-serif;
  z-index: 9999;
}

.Modal-show-loading-data-show-modal {
  opacity: 1;
  visibility: visible;
  transform: scaleX(1.0) scaleY(1.0);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}