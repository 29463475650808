// Here you can add other styles
.main_content{
    padding-top:20px
}

.swal2-container{
    z-index: 9999 !important;
}

.report-view{
    margin-top:20px
}

.report-viewer{
    height: 300px;
    overflow-y: scroll;
}

.report-viewer table{
    width: 100%;
}

.group-bao-cao td{
    background: #666;
    color: #fff
}

.search-control{
    width: 200px;
    margin-right: 10px;
    padding: 3px !important;
    height: auto;
}

.rc-tree li .rc-tree-node-content-wrapper{
    height: 25px!important;
}

.rc-tree-node-selected {
    background-color: #033863!important;
    border: none !important;
    color: #fff !important;
}

.MuiTableSortLabel-root{
    display: block !important;
    position: relative;
}

.MuiTableSortLabel-icon{
    font-size: 14px !important;
    position: absolute;
    top: 4px;
    right: -2px;
}

.radio{
  position: relative;
  margin-bottom: 0;
  margin-right: 10px;
  margin-top: 5px;
}

.radio input{
  position: relative;
  margin-top: 0;
  margin-left: 0;
  height: auto;
  margin-right: 5px;
}

.radio span{
  height: 100%;
  margin-right: 20px;
}

.radio .disabled{
  color: #ccc;
}

.row-level-1{
    font-size: 20px;
    font-weight: bold;
}
.row-level-2{
    font-size: 18px;
    font-weight: bold;
    font-style: italic;
    padding-left: 30px !important;
}
.row-level-3{
    font-size: 16px;
    padding-left: 50px !important;
}
.row-level-4{
    font-size: 14px;
    font-style: italic;
    padding-left: 70px !important;
}
.row-level-5{
    font-size: 12px;
    padding-left: 90px !important;
}

// .pdfViewer{
//     background: #ccc;
//   }

//   .pdfViewer .page {
//     direction: ltr;
//     /* width: 816px;
//     height: 1056px; */
//     margin: 0 auto;
//     position: relative;
//     overflow: visible;
//     /* border: 9px solid transparent;
//     background-clip: content-box;
//     -webkit-border-image: url(images/shadow.png) 9 9 repeat;
//          -o-border-image: url(images/shadow.png) 9 9 repeat;
//             border-image: url(images/shadow.png) 9 9 repeat;
//     background-color: white; */
//   }

.pdfViewer{
  .textLayer{
    display: none;
  }
}


  .table-minhon th, .table-minhon td{
        padding: 2px;
        font-size: 12px;
    }

    .table-minhon th{
        font-weight: bold;
        text-align: center;
        background-color: powderblue;
    }

    .avatar {
        background: #fff;
        border-radius: 50%;
        padding: 2px;
        position: relative;
        display: inline-block;
        width: 36px;
        height: 36px;
    }

    .avatar-status {
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;
        width: 10px;
        height: 10px;
        border: 1px solid #fff;
        border-radius: 50em;
    }

    .user-online .user-name{
        position:relative;
        padding-left:15px
    }

    .badge-gray {
        color: #fff;
        background-color: #ccc;
    }
